/* CSS Document */

/* Type Selector */
* {
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  user-select: none;
  -webkit-touch-callout: none;
}
html {
  font-size: 10px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  html {
    font-size: 8px;
  }
}
@media (min-width: 1400px) and (max-width: 1530px) {
  html {
    font-size: 9px;
  }
}

html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden !important;
}
body {
  color: #333;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
form,
fieldset,
p,
blockquote,
button,
table {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400;
}
img,
fieldset,
button {
  border: 0 none;
}
textarea {
  border: 1px solid #d9d9d9;
  overflow: auto;
}
ul,
ol {
  list-style: none;
}
input[type="radio"] {
  width: 14px;
  height: 14px;
  margin: 0;
  padding: 0;
}
input[type="text"]::-ms-clear {
  display: none;
}
select {
  vertical-align: middle;
  border: 1px solid #d9d9d9;
}
a {
  border: 0;
}
a:link {
  color: #333;
  text-decoration: none;
}
a:visited {
  color: #333;
  text-decoration: none;
}
a:hover {
  color: #da291c;
  text-decoration: none;
}
a:active {
  color: #333;
  text-decoration: none;
}
em {
  font-style: normal;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption {
  display: none;
}
fieldset,
button {
  border: none;
}
hr,
legend {
  display: none;
  clear: both;
}
iframe {
  width: 100%;
  height: 100%;
}
input {
  padding: 0;
}
label {
  vertical-align: middle;
  cursor: pointer;
}
table caption {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
}

textarea {
  width: 100%;
  height: 200px;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #c6c6c6;
  border-radius: 2px;
  border-radius: 2px;
  box-sizing: border-box;
  overflow: auto;
}
input[type="checkbox"] {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  border: none;
}
input[type="radio"] {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  border: none;
}
*:-webkit-auto:fill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
table {
  border-collapse: collapse;
  table-layout: fixed !important;
  border-spacing: 0;
}
ul,
ol,
li {
  list-style: none;
}
hr {
  display: none;
}
caption,
legend {
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}
iframe {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* 
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('/rem/apply/common/webfont/NotoSansKR-Light.eot');  
  src:  
       url('/rem/apply/common/webfont/notokr-light.ttf') format('truetype'), 		
       url('/rem/apply/common/webfont/NotoSansKR-Light.woff2') format('woff2'), 
       url('/rem/apply/common/webfont/NotoSansKR-Light.woff') format('woff'); 
      
  }  */

.hiddenZone {
  display: none;
}
.hiddenZoneV {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  *visibility: hidden;
}

.layer_setting {
  position: absolute;
  z-index: 1001;
  top: 5.2rem;
  right: 0;
  width: 38.6rem;
  height: calc(100vh - 5.2rem);
  color: #fff;
  background: #3c3f42;
}
/* 
.layer_setting .head {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 24px;
  border-bottom: 1px solid #e0dee0;
}

.layer_setting .head .btn_back {
  width: 32px;
  height: 32px;
  margin-right: 20px;
  background: url("../public/images/arrow_setting_back.svg") no-repeat left
    center;
  background-size: 32px 32px;
}

.layer_setting .head h2 {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 24px;
} */

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../public/font/montserrat-v18-latin-500.eot?#iefix"),
    url("../public/font/montserrat-v18-latin-500.woff2") format("woff2"),
    url("../public/font/montserrat-v18-latin-500.woff") format("woff"),
    url("../public/font/montserrat-v18-latin-500.ttf") format("truetype"),
    url("../public/font/montserrat-v18-latin-500.svg#Montserrat") format("svg");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../public/font/montserrat-v18-latin-700.eot?#iefix"),
    url("../public/font/montserrat-v18-latin-700.woff2") format("woff2"),
    url("../public/font/montserrat-v18-latin-700.woff") format("woff"),
    url("../public/font/montserrat-v18-latin-700.ttf") format("truetype"),
    url("../public/font/montserrat-v18-latin-700.svg#Montserrat") format("svg");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: url("../public/font/montserrat-v18-latin-black.eot?#iefix"),
    url("../public/font/montserrat-v18-latin-black.woff2") format("woff2"),
    url("../public/font/montserrat-v18-latin-black.woff") format("woff"),
    url("../public/font/montserrat-v18-latin-black.ttf") format("truetype"),
    url("../public/font/montserrat-v18-latin-black.svg#Montserrat")
      format("svg");
}

@font-face {
  font-family: "Titan-One";
  src: url("../public/font/Titan-One.ttf.woff") format("woff"),
    url("../public/font/Titan-One.ttf.svg#Titan-One") format("svg"),
    url("../public/font/Titan-One.ttf.eot"),
    url("../public/font/Titan-One.ttf.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
